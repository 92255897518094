import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  aboutFraser,
  mobileAbout,
  mobileAboutFraser,
  mobileAbout2,
  mobileAbout2Fraser,
  mobileAboutDivider,
  aboutDivider,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAboutDivider}
            w="100%"
            alt="aboutImageMobile"
            block
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          justifyStart
          w="100%"
          maxWidth="1400px"
          h="450px"
          image={`url(${aboutDivider}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
