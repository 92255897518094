import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFLink target="_blank" href={'https://goo.gl/maps/tmvMHVVWGstnd3j4A'}>
          <CFImage src={mobileMap} w="100%" alt="Koreana Map" />
        </CFLink>
      </MobileScreen>
      <DefaultScreen>
        <CFLink target="_blank" href={'https://goo.gl/maps/tmvMHVVWGstnd3j4A'}>
          <CFImage src={locationMap} w="100%" alt="Koreana Map" />
        </CFLink>
      </DefaultScreen>
    </CFView>
  )
}
