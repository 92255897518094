import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          alignCenter
          textCenter
        >
          <Header />
          <CFImage
            w="280px"
            mv="8%"
            maxWidth="100%"
            src={mobileHeroText}
            alt="Koreana hero text"
            zIndex={98}
          />
          <CFView pulsate>
            <OrderPickupButton />
          </CFView>
          <CFView mt="10px">
            <OrderDeliveryButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="630px"
          w="100%"
          maxWidth="1400px"
          boxShadow="0 2px 2px rgba(0,0,0,.8)"
          zIndex={90}
          ml="1%"
        >
          <CFView row justifyBetween w="100%" h="100%">
            <CFView w="35%" h="100%" column justifyCenter alignStart>
              <CFView row w="100%" ml="45px" mt="-10px">
                <CFImage
                  w="85%"
                  maxWidth="320px"
                  src={heroText}
                  alt="Koreana hero text"
                  zIndex={98}
                />
              </CFView>
              <CFView ml="45px" mt="4vh" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView ml="45px" mt="15px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
            <CFView
              w="65%"
              image={`url(${hero}) center / cover no-repeat`}
            ></CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
