import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  foodGallery,
  foodGalleryBurnaby,
  mobileGallery,
  mobileGalleryBurnaby,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let GalleryMobileImage = null
        let GalleryImage = null
        if (locationId === 'd8E2zyhodyjt9OOdmIYn') {
          GalleryImage = foodGalleryBurnaby
          GalleryMobileImage = mobileGalleryBurnaby
        } else {
          GalleryImage = foodGallery
          GalleryMobileImage = mobileGallery
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={GalleryMobileImage} w="100%" alt="Food Gallery" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1400px" m="0 auto">
                <CFImage src={GalleryImage} w="100%" alt="Food Gallery" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
