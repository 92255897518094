import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { mobilePromotion, promotions } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        /*let promotionMobileImage = null
        let promotionsImage = null
        if (locationId === 'UeW1ehQXkWV2jXhJ14lx') {
          promotionsImage = promotionsFraser
          promotionMobileImage = mobilePromotionFraser
        } else {
          promotionsImage = promotions
          promotionMobileImage = mobilePromotion
        }*/
        return (
          <CFView>
            <MobileScreen>
              <CFImage
                src={mobilePromotion}
                w="100%"
                alt="Koreana Map"
                style="display: block"
              />
            </MobileScreen>
            <DefaultScreen>
              <CFImage
                style="display: block"
                src={promotions}
                w="100%"
                alt="Koreana Map"
              />
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
