import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerbg, logo, logoTitle, viewMenu, mobileLogo } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%">
          <CFView row center>
            <CFImage w="80%" mt="15px" src={logo} alt="Koreana Logo" />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          bg="rgba(0,0,0,.8)"
          h="60px"
          zIndex={98}
          w="100%"
          maxWidth="1400px"
          ph="30px"
        >
          <CFView row justifyStart alignStart mt="1px">
            <CFImage
              h="8vh"
              minHeight="55px"
              maxHeight="140px"
              ml="30px"
              src={logo}
              alt="Koreana Logo"
              zIndex={98}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
